export default {
  methods: {
    showGradesChartDialog(type, currentGrade) {
      this.$store.dispatch('dialog/alert', {
        title: this.$t('components.gradesChart.header'),
        text: this.$t('components.gradesChart.explanation'),
        component: () => import('./tl-grades-chart'),
        componentProps: { type, currentGrade },
        maxWidth: 500,
      })
    },
  },
}
