<template>
  <div>
    <p>{{ $t('competitions.info.pointsByGrade.description') }}</p>

    <v-btn class="mb-3" text @click="showGradesChart">
      {{ $t('competitions.info.pointsByGrade.button') }}
    </v-btn>

    <ul class="mb-4">
      <li>
        <span>{{ $t('climbs.log.top') }}: </span>
        <span>{{ $t('competitions.info.pointsByGrade.explanation') }}</span>
      </li>
      <li v-if="params.points_flash > 0">
        <span>{{ $t('climbs.log.flash') }}: </span>
        <span>{{ $t('competitions.info.points.bonus', { points: params.points_flash }) }}</span>
      </li>
      <li v-if="params.points_onsight > 0">
        <span>{{ $t('climbs.log.onsight') }}: </span>
        <span>{{ $t('competitions.info.points.bonus', { points: params.points_onsight }) }}</span>
      </li>
      <li v-if="params.n_best_efforts != 10000">
        <span>{{ $t('competitions.info.pointsByGrade.efforts', { efforts: params.n_best_efforts }) }}</span>
      </li>
    </ul>

    <div v-if="isEditor">
      <div class="title">Configuration</div>
      <v-text-field
        :value="params.points_flash"
        type="number"
        label="Bonus points for flash"
        @input="setParam('points_flash', $event)"
      />
      <v-text-field
        :value="params.points_onsight"
        type="number"
        label="Bonus points for on-sight"
        @input="setParam('points_onsight', $event)"
      />
      <v-text-field
        :value="params.n_best_efforts"
        type="number"
        label="Only count best n results"
        hint="Use a very high number to count all ascents"
        persistent-hint
        @input="setParam('n_best_efforts', $event)"
      />
    </div>
  </div>
</template>

<script>
import scoreSystemMixin from './score-system.mixin'
import tlGradesChartDialogMixin from '@/components/shared/tl-grades-chart/tl-grades-chart.dialog.mixin'

export default {
  mixins: [scoreSystemMixin, tlGradesChartDialogMixin],
  data: () => ({
    gradesChartDialog: false,
  }),
  methods: {
    showGradesChart() {
      this.showGradesChartDialog(this.comp.climbs_type)
    },
  },
}
</script>
